import React, { useRef, useEffect } from 'react';
import home from '../images/home.jpg';
import '../css/home.css';
import { Link } from 'react-router-dom';
import logo from "../images/logo.png";
import { motion } from 'framer-motion'; 

import logo1 from "../images/home/1.jpg";
import logo2 from "../images/home/2.jpg";
import logo3 from "../images/home/3.jpg";
import logo4 from "../images/home/4.jpg";
import logo5 from "../images/home/5.jpg";
import logo6 from "../images/home/6.jpg";
import logo7 from "../images/home/7.jpg";
import logo8 from "../images/home/8.jpg";
import logo9 from "../images/home/9.jpg";
import logo10 from "../images/home/10.jpg";

function Home() {
  const galleryRef = useRef(null);

  useEffect(() => {
    const gallery = galleryRef.current;

    const handleMouseMove = (e) => {
      const width = gallery.offsetWidth;
      const height = gallery.offsetHeight;
      const x = e.pageX - gallery.offsetLeft;
      const y = e.pageY - gallery.offsetTop;

      const rotateX = ((y / height) - 0.5) * 5;
      const rotateY = ((x / width) - 0.5) * -5;

      gallery.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const resetTransform = () => {
      gallery.style.transform = 'rotateX(0deg) rotateY(0deg)';
    };

    if (gallery) {
      gallery.addEventListener('mousemove', handleMouseMove);
      gallery.addEventListener('mouseleave', resetTransform);
    }

    return () => {
      if (gallery) {
        gallery.removeEventListener('mousemove', handleMouseMove);
        gallery.removeEventListener('mouseleave', resetTransform);
      }
    };
  }, []);

  const images = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10];

  return (
    <>
      <div className='container'>
        <motion.div
          className='welcomeWrite'
          initial={{ opacity: 0, y: -50 }}  
          whileInView={{ opacity: 1, y: 0 }}   
          transition={{ duration: 1 }}   
        >
          <h1>Benvenuto in Crono Italia</h1>
          <h2><i>Dove il tempo e le macchine creano gli amici</i></h2>
        </motion.div>
          <div className='bgImg'>
          <motion.span
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }} 
        >
          <img src={home} alt="homepage" className='imgHome' />
        </motion.span>
          </div>
      </div>

      <div className='mainBlock'>
        <motion.div
          className='block'
          initial={{ scale: 0.8 }}  
          whileInView={{ scale: 1 }}  
          transition={{ duration: 0.5 }}
        >
          <h1>SCOPRI DI PIU' SU COSA FACCIAMO</h1>
        </motion.div>
      </div>

      <div className='start'>
        <motion.div
          className='block1'
          initial={{ opacity: 0, x: -100 }} 
          whileInView={{ opacity: 1, x: 0 }} 
          transition={{ duration: 0.8 }}
        >
          <h1>Elaborazione dei dati acquisiti</h1>
          <hr className='hrBlock' />
          <h4>Grazie ai nostri sistemi, possiamo fornirvi tutti i prospetti tempi, classifiche in men che non si dica!</h4>
        </motion.div>

        <motion.div
          className='block2'
          initial={{ opacity: 0, x: 100 }} 
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1>Possibilitá di organizzazione delle prove</h1>
          <hr className='hrBlock' />
          <h4>Possiamo aiutarvi ad organizzare le prove che avete intenzione di fare</h4>
          <Link to='/contattaci' className='buttonBlock'>Clicca qui</Link>
        </motion.div>

        <motion.div
          className='block3'
          initial={{ opacity: 0, y: 50 }} 
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1>Classiche e Archivio Web</h1>
          <hr className='hrBlock' />
          <h4>In questa pagina é possibile vedere tutte le manifestazioni fatte sino ad ora</h4>
          <Link to='/manifestazioni' className='buttonBlock'>Clicca qui</Link>
        </motion.div>
      </div>

      <div className='containerGalleryText'>
  <motion.div className="gallery" ref={galleryRef}>
    {images.map((image, index) => (
      <motion.div
        key={index}
        className={`item item${index + 1}`}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }} 
        transition={{
          duration: 0.5,          
          delay: index * 0.3 
        }}
        whileHover={{ scale: 1.1 }}
      >
        <img src={image} alt={`image ${index + 1}`} className="gallery-image" />
      </motion.div>
    ))}
  </motion.div>

  <div className='textDiv'>
    <motion.h1
      className='titleDiv'
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      Oltre 20 anni di esperienza nel settore dei motori e piú di 500 manifestazioni cronometrate
    </motion.h1>

    <motion.hr
      className='hrBlock'
      initial={{ opacity: 0, width: 0 }}
      whileInView={{ opacity: 1, width: '100%' }}
      transition={{ duration: 1 }}
    />

    <motion.h3
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.8 }}
    >
      Siamo presenti sul territorio italiano da oltre 20 anni, un'esperienza che ci ha reso professionisti nel settore. <br></br>
      Abbiamo contribuito a piú di 500 manifestazioni, garantendo sempre servizi di cronometraggio precisi.
    </motion.h3>

    <motion.div
      className='flexDiv'
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ delay: 0.4, duration: 0.8 }}
    >
      <motion.div
        className='colDiv'
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <h3>Se volessi scoprire di più su chi siamo puoi cliccare qua sotto</h3>
        <motion.div
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <Link to='chi-siamo' className='buttonDiv'>
            Clicca qui per scoprire chi siamo
          </Link>
        </motion.div>
      </motion.div>

      <motion.div
        className='colDiv'
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <h3>Se invece hai delle domande da farci puoi cliccare qui</h3>
        <motion.div
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <Link to='contattaci' className='buttonDiv' onClick={() => { window.scrollTo(0, 0); }}>
            Clicca qui per contattarci
          </Link>
        </motion.div>
      </motion.div>
    </motion.div>
  </div>
</div>


      <div className='manifestazioni'>
        <hr className='hr' />
        <div className='blockman'>
          <motion.h1
            className='textHome'
            initial={{ opacity: 0, y: -30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Ultime Manifestazioni Fatte
          </motion.h1>
        </div>

        <div className='disposizioneManifestazioni'>
          {[...Array(9)].map((_, index) => (
            <motion.img
              key={index}
              src={logo}
              alt='manifestazione'
              className='manifestazioneImg'
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                delay: index * 0.3,
                duration: 0.2,
                ease: "easeInOut"
              }}
              whileHover={{ scale: 1.1 }}
            />
          ))}
        </div>
      </div>

      <div className='endHome'>
        <div className='textEnd'>
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Vuoi chiederci qualcosa?
          </motion.h3>
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Siamo qui per rispondere a tutte le tue domande!
          </motion.h3>
        </div>
        <motion.div
          whileHover={{ scale: 1.1 }}
        >
          <Link to="/contattaci" className='contactButton' onClick={() => { window.scrollTo(0, 0); }} >
            Clicca qui
          </Link>
        </motion.div>
      </div>
    </>
  );
}

export default Home;
