import React from 'react';
import ManifestazioniPhoto from '../images/manifestazioniPhoto.jpg';
import logo from "../images/logo.png";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'; 

function Manifestazioni() {
  return (
    <>
      <motion.div
        className='contactContainer'
        initial={{ opacity: 0, y: -50 }} 
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className='textHeaderUp'>/Manifestazioni</h1>
        <h1 className='textHeader'>Qui potrai vedere tutte le manifestazioni fatte</h1>
        <img src={ManifestazioniPhoto} alt='Manifestazioni Foto' className='contactImg' />
      </motion.div>

      <motion.div
        className='manifestazioni'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }} 
      >
        <h1 className='orange'>Archivio Manifestazioni</h1>

        <div className='disposizioneManifestazioni'>
          {[...Array(16)].map((_, index) => (
            <motion.img
              key={index}
              src={logo}
              alt='manifestazione'
              className='manifestazioneImg'
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }} 
              whileHover={{ scale: 1.1 }} 
            />
          ))}
        </div>
      </motion.div>

      <div className='endHome'>
        <div className='textEnd'>
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Hai un'idea per delle prove cronometrate?
          </motion.h3>
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Clicca sul pulsante e contattaci!
          </motion.h3>
        </div>
        <motion.div
          whileHover={{ scale: 1.1 }}
        >
            <Link to="/contattaci" className='contactButton' onClick={() => { window.scrollTo(0, 0); }} >Clicca qui</Link>
        </motion.div>
      </div>
    </>
  );
}

export default Manifestazioni;
