import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import "../css/header.css";
import logo from "../images/logo.png";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToTop = () => {
    const topElement = document.getElementById("top");
    if (topElement && typeof topElement.scrollIntoView === 'function') {
      topElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <span id="top"></span>

      <motion.nav
        className={`bg ${menuOpen ? "menu-open" : ""}`}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div className="logop" whileHover={{ scale: 1.05 }}>
          <Link to="/"><img src={logo} alt="logo" className="logo" /></Link>
        </motion.div>

        <motion.div
          className={`infop ${menuOpen ? "open" : "closed"}`}
          animate={menuOpen ? { x: 0 } : { x: -10 }}
          transition={{ duration: 0.5 }}
        >
<NavLink 
  to="/" 
  className="nav-link mr10" 
  end 
  onClick={() => { 
    window.scrollTo(0, 0); 
    toggleMenu(); 
  }}
>
  Home
</NavLink>
<NavLink 
  to="/manifestazioni" 
  className="nav-link mr10" 
  onClick={() => { 
    window.scrollTo(0, 0); 
    toggleMenu(); 
  }}
>
  Manifestazioni
</NavLink>
<NavLink 
  to="/chi-siamo" 
  className="nav-link mr10" 
  onClick={() => { 
    window.scrollTo(0, 0); 
    toggleMenu(); 
  }}
>
  Chi Siamo
</NavLink>
<NavLink 
  to="/contattaci" 
  className="nav-link mr10" 
  onClick={() => { 
    window.scrollTo(0, 0); 
    toggleMenu(); 
  }}
>
  Contattaci
</NavLink>
<NavLink 
  to="/galleria" 
  className="nav-link mr10" 
  onClick={() => { 
    window.scrollTo(0, 0); 
    toggleMenu(); 
  }}
>
  Galleria
</NavLink>

        </motion.div>

        <motion.button
          className="menu-toggle"
          onClick={toggleMenu}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 200, damping: 15 }}
        >
          {/* Struttura per i tre trattini dell'hamburger */}
          <motion.span
            className="line top"
            animate={menuOpen ? { rotate: 45, y: 8 } : { rotate: 0, y: 0 }}
            transition={{ type: "spring", stiffness: 200, damping: 15 }}
          />
          <motion.span
            className="line middle"
            animate={menuOpen ? { opacity: 0 } : { opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          />
          <motion.span
            className="line bottom"
            animate={menuOpen ? { rotate: -45, y: -8 } : { rotate: 0, y: 0 }}
            transition={{ type: "spring", stiffness: 200, damping: 15 }}
          />
        </motion.button>
      </motion.nav>

      <motion.button
  className="topPage"
  onClick={scrollToTop}
  whileHover={{ scale: 1.2 }}
  whileTap={{ scale: 0.95 }}
  transition={{ duration: 0.2 }}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="white"
    width="30px"
    height="30px"
  >
    <path d="M12 2l8.5 8.5-1.41 1.41L13 6.83V22h-2V6.83l-6.09 6.09L3.5 10.5z" />
  </svg>
</motion.button>


    </>
  );
}

export default Header;
