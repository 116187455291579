import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Manifestazioni from './pages/Manifestazioni';
import ChiSiamo from './pages/ChiSiamo';
import Contattaci from './pages/Contattaci';
import Header from './components/header';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/footer';
import Galleria from './pages/Galleria';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/chi-siamo" element={<ChiSiamo />} />
      <Route path="/manifestazioni" element={<Manifestazioni />} />
      <Route path="/contattaci" element={<Contattaci />} />
      <Route path="/galleria" element={<Galleria />} />
    </Routes>
    <Footer />
  </Router>
);

reportWebVitals();
